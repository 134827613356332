import { getFirestore, addDoc, collection } from "firebase/firestore";

import helpers from "@/helpers/global";
const CN = "tracking";

interface TrackingData {
  email?: string;
}

const DEBUG = false;

export default {
  namespaced: true,
  state: {
    error: null,
    data: null,
  },
  mutations: {
    error(state: { error: boolean }, error: boolean): any {
      state.error = error;
    },
    data(state: { data: TrackingData }, data: TrackingData): any {
      state.data = data;
    },
  },
  getters: {
    error(state: { error: boolean }): any {
      return state.error;
    },
    data(state: { data: TrackingData }): TrackingData {
      return state.data;
    },
  },
  actions: {
    async save({ commit }: { commit: any }, data: TrackingData): Promise<any> {
      if (process.env.VUE_APP_TRACKING_ENABLED !== "on") {
        if (DEBUG) {
          console.log("Tracking disabled.");
          console.log(data, "data");
        }
        return;
      }
      commit("error", false);

      if (!data.email) {
        data.email = "";
      }

      const _save = async (ip) => {
        const trackingData = {
          ...data,
          ip: ip,
          created_at: helpers.now(),
        };
        const fs = getFirestore();
        await addDoc(collection(fs, CN), trackingData);
      };

      const doGetIp = false;
      if (doGetIp) {
        await fetch("https://api.ipify.org?format=json")
          .then((x) => x.json())
          .then(async ({ ip }) => {
            await _save(ip);
          });
      } else {
        const ip = "127.0.0.1";
        await _save(ip);
      }
    },
  },
};
